import React from 'react';
import { galenApi } from '../utility/galen-api';
import css from './../css/login_components.module.css'
// regex email validator
let regexEmailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


class LoginControl extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
		email: null,
		password: null,
		authState:null,
		authMessage:''
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {

		const target = event.target;
		const value = target.value;
		const name = target.name;

		// validate email address and set state if valid
		if (target.type === 'email') {
		if ( regexEmailValidator.test(target.value) ) this.setState({ email: target.value,});
		else {
			this.setState({ email: null, });
		}
		} else if (target.type === 'password') {
		if (target.value.length < 6)  this.setState({ password: null, });
		else this.setState({ password: target.value, });

		}
		// for other fields just set the state without validation
		else this.setState({ [name]: value });
	}

	async handleSubmit(event) {

		event.preventDefault();
		
		if (!this.state.email || !this.state.password) {
			this.setState({authState:'not-min-credentials', authMessage:'Email or password does not meet format or length requirements'});
			return;
		}

		this.setState({authState:'sending', authMessage:''});
		let response = await galenApi.login(this.props.isProd,this.state.email,this.state.password);

		if(!response.success) {
			this.setState({authState : 'failed', authMessage : response.errorMessage});
			return;
		}

		this.setState({authState: 'complete'});
		this.props.handleLogin(response.token);
	}
	
	render() {

		return (
		<div className={css.login_div}>
			<form onSubmit={this.handleSubmit}>
			<h2>Log into PhotoniCloud {this.props.isProd ? '': ' - Dev'}</h2>
			<label>
				Email:
				<input className={css.login_input} type="email" value={this.state.value} onChange={this.handleChange} />
			</label>
			<label>
				Password:
				<input className={css.login_input} type="password" value={this.state.value} onChange={this.handleChange} />
			</label>
			<input className={css.login_submit} type="submit" value="Sign In" />
			</form>
			<div className={css.login_message}>{this.state.authMessage}</div>
		</div>
		)
	}
}

export { LoginControl }

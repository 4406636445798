import React from 'react';
import css from './../css/toolbar_components.module.css'

function TopBarControl(props)  {

  return (
    <div className={css.navbar}>
      <div className={css.navbar_container}>
        <span className={css.navbar_button} onClick={props.handleBack}>Back</span>
        <span className={css.navbar_button} onClick={props.handleLogout}>Logout</span>
      </div>
    </div>
  )
}

function BottomBarControl (props) {

  let recordingsTotalDisplay = null;
  if(props.currentRecording > 0 && props.totalRecordings > 0) recordingsTotalDisplay = props.currentRecording + " of " + props.totalRecordings;

  return (
    <div className={css.navbar}>
      <div className={css.navbar_container}>
        <span className={css.navbar_button} onClick={props.handlePrevious}>Previous</span>
        <span>{recordingsTotalDisplay}</span>
        <ExportSegmentButton handleExportRequest={props.handleExportSegmentRequest} exportRequestStatus={props.exportRequestStatus}></ExportSegmentButton>
        <span className={css.navbar_button} onClick={props.handleNext}>Next</span>
      </div>
    </div>
  )
}

// button is connected to recording component via exam viewer component. 
function ExportSegmentButton (props) {
  if (props.exportRequestPending === true) {
    return <span className={css.navbar_button} onClick={props.handleExportRequest}>Exporting...</span>
  }
  else {
    return <span className={css.navbar_button} onClick={props.handleExportRequest}>Export Segment</span>
  }
}

function ExamInfoBar (props) {

  const examInfo = {...props.examInfo};
  const examInfoListItems = [];

  for (const info in examInfo) {
    examInfoListItems.push(
      <li key={info}>
        {info} : {examInfo[info]}
      </li>
    );
  }
  
  return (
    <div className='exam-info-bar'>
      <ul> {examInfoListItems}</ul>
    </div>
  )
}

export {TopBarControl, BottomBarControl, ExamInfoBar}

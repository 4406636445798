import React from 'react';
import css from './../css/recording_components.module.css'
import { data, evaluate_cmap } from '../dependancies/js-colormaps/js-colormaps';
// RecordingImageDisplay is a component that displays a recording on a canvas.
// Surface image and LCI are displayed in device review mode format
class RecordingImageDisplay extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      surfaceImage: null,
      surface: null,
      lciImage: null,
      lci: null,

      crosshairEnabled: true,
      turbidityState: 'none',
      addTicks: false,
      selectedColorMap: 'gist_ncar',
      normalizedSelected: false,
      backgroundRemovalSelected: false,
      depthIntensityCompensation: false,
      showArtifactList: false,
      artifactInfo: undefined,
      highlightedArtifact: -1,

      canvasDimensions: null,
      canvasLoaded: false,
      canvasLoadStarted: false,
      errors: {
        hasImageLoadError: false,
        hasExamInfoLoadError: false,
      }
    };
    this.isDragging = false
    this.canMouseX = 0;
    this.canvasRef = React.createRef();
    this.lciMarginLeft = 20;
    this.fixedArtifactExamStart = 20; // 20 seconds to start artifact detection
    this.fixedArtifactExamDuration = 10; // 10 seconds duration
    this.fixedArtifactExamPixelsStart = this.fixedArtifactExamStart * 40; // data @ 40 FPS, unscaled number of pixels
    this.fixedArtifactExamPixelsDuration = this.fixedArtifactExamDuration * 40;
    this.exportCanvasRef = React.createRef();
    this.lciPosition = 0;
    this.originalLciPosition = 0;
    this.surfaceImageIndex = 0;
    this.turbidityPosition = null;
    this.originalturbidityPosition = {};
    this.renderedImages = {};
    this.turbidityBoxSize = { width: 48, height: 32 };
    this.colormapList = ['none'];
  }

  loadImages = () => {
    this.setState({ canvasLoadStarted: true });
    const surfaceImage = new Image();
    surfaceImage.onload = () => { this.setState({ surfaceImage: surfaceImage }); }
    surfaceImage.onerror = () => {
      let errors = { ...this.state.errors }
      errors.hasImageLoadError = true;
      this.setState({ errors: errors });
    }
    surfaceImage.src = this.props.recording.surfaceUrl;

    const lciImage = new Image();
    lciImage.onload = () => { this.setState({ lciImage: lciImage }); }
    lciImage.onerror = () => {
      let errors = { ...this.state.errors }
      this.setState({ errors: errors });
    }
    lciImage.src = this.props.recording.lciUrl;
  }

  initSurfaceCanvas = () => {
    // if canvas isn't ready yet, wait for update
    if (this.canvasRef.current === null) return;

    let ctx = this.canvasRef.current.getContext('2d');
    let canvasDimensions = {
      height: ctx.canvas.clientHeight,
      width: ctx.canvas.clientWidth,
      surfaceImageSize: { x: 480, y: 480 },
      naturalLciHeight: 480,
      maxCombinedSurfaceImageWidth: 32640,
      boundingClientRect: this.canvasRef.current.getBoundingClientRect(),
      crosshairLength: 20,
      crosshairStartFromCenter: 20,
      zoomScaleFactor: 3
    }

    canvasDimensions.surfaceHeightOnCanvas = canvasDimensions.height / 2;
    canvasDimensions.surfaceWidthOnCanvas = canvasDimensions.height / 2;
    canvasDimensions.lciHeightOnCanvas = canvasDimensions.height / 5 * 2;
    canvasDimensions.lciTopLeftY = canvasDimensions.height / 5 * 3;
    canvasDimensions.surfaceTopLeftX = (canvasDimensions.width - canvasDimensions.surfaceWidthOnCanvas) / 2;
    canvasDimensions.surfaceTopLeftY = 10;
    canvasDimensions.surfaceCenterX = canvasDimensions.width / 2;
    canvasDimensions.surfaceCenterY = canvasDimensions.surfaceHeightOnCanvas / 2 + 10;

    this.canvasRef.current.width = canvasDimensions.width;
    this.canvasRef.current.height = canvasDimensions.height;

    // location and dimension of zoomed turbidity box
    this.zoomTurbidityBoxDims = {
      x: Math.round(canvasDimensions.width / 20 * 17 - this.turbidityBoxSize.width / 2 * canvasDimensions.zoomScaleFactor),
      y: Math.round(canvasDimensions.surfaceCenterY - this.turbidityBoxSize.height / 2 * canvasDimensions.zoomScaleFactor),
      width: Math.round(this.turbidityBoxSize.width * canvasDimensions.zoomScaleFactor),
      height: Math.round(this.turbidityBoxSize.height * canvasDimensions.zoomScaleFactor),
    }

    // location and dimension of zoomed lci
    // add a 20% buffer around the turbidity box
    this.zoomTurbidityDims = {
      x: Math.round(this.zoomTurbidityBoxDims.x - this.zoomTurbidityBoxDims.width * 0.5),
      y: Math.round(this.zoomTurbidityBoxDims.y - this.zoomTurbidityBoxDims.height * 2),
      width: Math.round(this.zoomTurbidityBoxDims.width * 2),
      height: Math.round(this.zoomTurbidityBoxDims.height * 5),
    }


    this.lciPosition = Math.max(0, canvasDimensions.width / 2 - this.state.lciImage.naturalWidth);

    // get lci pixel data
    this.lciCanvas = document.createElement('canvas')
    this.lciCanvas.width = this.state.lciImage.width;
    this.lciCanvas.height = this.state.lciImage.height;
    this.lciCtx = this.lciCanvas.getContext('2d');
    this.lciCtx.drawImage(this.state.lciImage, 0, 0, this.state.lciImage.width, this.state.lciImage.height);
    let lciImageData = this.lciCtx.getImageData(0, 0, this.lciCanvas.width, this.lciCanvas.height);

    // create a copy of the underlying data
    let tempArray = new Uint8ClampedArray(lciImageData.data);
    this.lciImageData = this.lciCtx.createImageData(lciImageData);
    this.lciImageData.data.set(tempArray);

    // create a scaled version of the lci for displaying zoom indicator - do it once during init instead of on the fly to fix hangs in UI
    this.zoomLciCanvas = document.createElement('canvas')
    this.zoomLciCanvas.width = this.state.lciImage.width * canvasDimensions.zoomScaleFactor;
    this.zoomLciCanvas.height = this.state.lciImage.height * canvasDimensions.zoomScaleFactor;
    this.zoomLciCtx = this.zoomLciCanvas.getContext('2d');

    //this.zoomLciCtx.putImageData(scaleImageData(this.lciImageData, 4, this.lciCtx), 0, 0);

    // create the good signal indicator
    this.signalImageData = createGoodSignalLine(this.lciImageData, this.lciCtx);

    this.canvasDimensions = canvasDimensions;
    this.ctx = ctx;

    this.setState({

      canvasLoaded: true
    }, () => { this.updateCanvas(); });
  }

  updateCanvas = () => {

    let canvasDimensions = { ...this.canvasDimensions };
    let ctx = this.ctx;
    ctx.restore();
    // clear the canvas before writing to it
    ctx.clearRect(0, 0, canvasDimensions.width, canvasDimensions.height);
    ctx.beginPath();

    ctx.save()

    //draw outline around surface image and down to lci
    ctx.strokeStyle = 'blue'
    ctx.lineWidth = 10;
    ctx.beginPath();
    ctx.arc(canvasDimensions.surfaceCenterX, canvasDimensions.surfaceCenterY, canvasDimensions.surfaceWidthOnCanvas / 2, canvasDimensions.surfaceHeightOnCanvas / 2, Math.PI * 2, true);
    ctx.moveTo(canvasDimensions.surfaceCenterX, canvasDimensions.surfaceTopLeftY + canvasDimensions.surfaceHeightOnCanvas);
    ctx.lineTo(canvasDimensions.surfaceCenterX, canvasDimensions.lciTopLeftY);
    ctx.stroke();

    // ** draw the lci image
    ctx.drawImage(this.lciCanvas, 0, 0, this.lciCanvas.width, this.lciCanvas.height, this.lciPosition, canvasDimensions.lciTopLeftY, this.lciCanvas.width, this.canvasDimensions.lciHeightOnCanvas);

    // draw the good signal indicator
    ctx.putImageData(this.signalImageData, this.lciPosition, canvasDimensions.lciTopLeftY - 10);

    // if there is a turbidity analysis location set, show the location in the canvas
    // y scaling is needed as the lci height is scaled to take up a fixed % of the screen 
    let scaledturbidityBoxSizeX = this.turbidityBoxSize.width;
    let scaledturbidityBoxSizeY = Math.round(this.turbidityBoxSize.height / 480 * this.canvasDimensions.lciHeightOnCanvas);

    if (this.turbidityPosition !== null) {

      // location of box to be drawn (top left coord)
      let y = this.turbidityPosition.y + this.canvasDimensions.lciTopLeftY;
      let x = this.turbidityPosition.x - scaledturbidityBoxSizeX / 2 + this.lciPosition;

      ctx.strokeStyle = 'white'
      ctx.lineWidth = 1;
      ctx.beginPath();
      // draw border around area of turbidity analysis
      ctx.moveTo(x, y);
      ctx.lineTo(x, y + scaledturbidityBoxSizeY);
      ctx.lineTo(x + scaledturbidityBoxSizeX, y + scaledturbidityBoxSizeY);
      ctx.lineTo(x + scaledturbidityBoxSizeX, y);
      ctx.lineTo(x, y);
      ctx.stroke();
    }
    else if (this.state.turbidityState === 'set') {
      // if in turbidity analysis setting mode, show a rectange to be placed on the mouse cursor
      ctx.strokeStyle = 'white'
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(canvasDimensions.width / 2 - scaledturbidityBoxSizeX / 2, this.mouseY);
      ctx.lineTo(canvasDimensions.width / 2 - scaledturbidityBoxSizeX / 2, this.mouseY + scaledturbidityBoxSizeY);
      ctx.lineTo(canvasDimensions.width / 2 + scaledturbidityBoxSizeX / 2, this.mouseY + scaledturbidityBoxSizeY);
      ctx.lineTo(canvasDimensions.width / 2 + scaledturbidityBoxSizeX / 2, this.mouseY);
      ctx.lineTo(canvasDimensions.width / 2 - scaledturbidityBoxSizeX / 2, this.mouseY);
      ctx.stroke();

      // show a zoomed in lci image around the turbidity box
      // get the location to retrive from the zoomed version of the lci
      let x = Math.round(canvasDimensions.width / 2 - this.lciPosition - this.turbidityBoxSize.width / 2) * this.canvasDimensions.zoomScaleFactor;
      x -= Math.round(this.zoomTurbidityBoxDims.x - this.zoomTurbidityDims.x);
      let y = Math.round((this.mouseY - canvasDimensions.lciTopLeftY) / canvasDimensions.lciHeightOnCanvas * 480) * this.canvasDimensions.zoomScaleFactor;
      y -= Math.round(this.zoomTurbidityBoxDims.y - this.zoomTurbidityDims.y);

      // get the data from the zoomed in version of the lci
      let zoomImageData = this.zoomLciCtx.getImageData(x, y, this.zoomTurbidityDims.width, this.zoomTurbidityDims.height);
      // place it on the canvas
      ctx.putImageData(zoomImageData, this.zoomTurbidityDims.x, this.zoomTurbidityDims.y);

      ctx.beginPath();
      ctx.strokeStyle = 'white'
      ctx.lineWidth = 1;
      ctx.moveTo(this.zoomTurbidityBoxDims.x, this.zoomTurbidityBoxDims.y);
      ctx.lineTo(this.zoomTurbidityBoxDims.x + this.zoomTurbidityBoxDims.width, this.zoomTurbidityBoxDims.y);
      ctx.lineTo(this.zoomTurbidityBoxDims.x + this.zoomTurbidityBoxDims.width, this.zoomTurbidityBoxDims.y + this.zoomTurbidityBoxDims.height);
      ctx.lineTo(this.zoomTurbidityBoxDims.x, this.zoomTurbidityBoxDims.y + this.zoomTurbidityBoxDims.height);
      ctx.lineTo(this.zoomTurbidityBoxDims.x, this.zoomTurbidityBoxDims.y);
      ctx.stroke();
    }

    // draw the turbidity speedometer
    if (this.state.turbidityRatio) {

      let radius = canvasDimensions.surfaceWidthOnCanvas / 3

      // background
      ctx.beginPath();
      ctx.fillStyle = `rgb(200,200,200)`
      ctx.arc(canvasDimensions.width / 20 * 17, canvasDimensions.surfaceCenterY, radius, -Math.PI, 0, false)
      ctx.fill();

      // green marking
      ctx.beginPath();
      ctx.strokeStyle = 'green';
      ctx.lineWidth = 10;
      ctx.arc(canvasDimensions.width / 20 * 17, canvasDimensions.surfaceCenterY, radius - 10, -Math.PI + 0.1, -2 / 3 * Math.PI, false)
      ctx.stroke();


      // yellow marking
      ctx.beginPath();
      ctx.strokeStyle = 'yellow';
      ctx.arc(canvasDimensions.width / 20 * 17, canvasDimensions.surfaceCenterY, radius - 10, -2 / 3 * Math.PI, -1 / 3 * Math.PI, false)
      ctx.stroke();

      ctx.beginPath();
      ctx.strokeStyle = 'orange';
      ctx.arc(canvasDimensions.width / 20 * 17, canvasDimensions.surfaceCenterY, radius - 10, -1 / 3 * Math.PI, -0.1, false)
      ctx.stroke();

      // needle
      let needlePosition = -Math.PI/2 + turbidityRatioToSpeedometerLocation(this.state.turbidityRatio) * Math.PI;
      ctx.beginPath();
      ctx.strokeStyle = 'black'
      ctx.moveTo(canvasDimensions.width / 20 * 17, canvasDimensions.surfaceCenterY);

      // work out needle ending location
      let endX = Math.sin(needlePosition) * radius + canvasDimensions.width / 20 * 17;
      let endY = canvasDimensions.surfaceCenterY - Math.cos(needlePosition) * radius;
      ctx.lineTo(endX, endY);
      ctx.stroke();

      // labels
      ctx.font = "15px Arial";
      ctx.fillStyle = "black";
      ctx.fillText("No fluid", canvasDimensions.width / 20 * 17 - radius * 1.2, canvasDimensions.surfaceCenterY + 20);
      ctx.fillText("High turbidity", canvasDimensions.width / 20 * 17 + radius * 0.5, canvasDimensions.surfaceCenterY + 20);
      ctx.font = "20px Arial";
      ctx.textAlign = "center";
      ctx.fillText("Turbidity Score: " + Math.round(this.state.turbidityRatio * 100) / 100, canvasDimensions.width / 20 * 17, canvasDimensions.surfaceCenterY + 50);

      // draw turbidity score
      // TO DO
    }

        // Draw artifact line indicators on the left margin
    // NOTE: this covers part of the LCI image if the image extends to the left side of the screen
    // If this is an issue I can fix it but this is the fastest way to implement this.
    if(this.state.artifactInfo) {
      ctx.strokeStyle = 'rgb(240, 240, 240)';
      ctx.lineWidth = this.lciMarginLeft;
      ctx.beginPath();
      const marginLeft = Math.max(0, this.lciPosition);
      const marginCenter = marginLeft + Math.floor(this.lciMarginLeft / 2);
      ctx.moveTo(marginCenter, canvasDimensions.lciTopLeftY);
      ctx.lineTo(marginCenter, canvasDimensions.lciTopLeftY + canvasDimensions.lciHeightOnCanvas);
      ctx.stroke();

      // artifact.line is the line from the lci image, where height = 480 pixels
      // The LCI drawn on the canvas is not necessarily to scale.
      // Find the correct ratio
      const lineRatio = canvasDimensions.lciHeightOnCanvas / this.lciCanvas.height;
      for(let i = 0; i < this.state.artifactInfo.artifactList.length; i++) {
        ctx.beginPath();
        ctx.strokeStyle = (this.state.highlightedArtifact == i.toString()) ? 'green' : 'orangered';
        let artifact = this.state.artifactInfo.artifactList[i];
        let lineOffset = Math.floor((artifact.line + artifact.width / 2) * lineRatio);
        let lineY = Math.floor(canvasDimensions.lciTopLeftY + lineOffset);
        ctx.lineWidth = Math.ceil(artifact.width * lineRatio / 2.0) * 2; // Divide by 2, round up, and multiply by 2 to ensure width is even and no sub-pixel rendering is used.
        ctx.moveTo(marginLeft, lineY);
        ctx.lineTo(marginLeft + this.lciMarginLeft, lineY);
        ctx.stroke();
      }
    }

    // ** draw the surface image

    // Create a circular clipping path for the surface image
    ctx.beginPath();
    ctx.arc(canvasDimensions.surfaceCenterX, canvasDimensions.surfaceCenterY, canvasDimensions.surfaceWidthOnCanvas / 2, canvasDimensions.surfaceHeightOnCanvas / 2, Math.PI * 2, true);

    ctx.clip();
    // calculate where in the surface image we should get the right image from based on the current position of the LCI
    this.updateSurfaceAndLciImage();
    //draw the surface image based on the location of the LCI
    ctx.drawImage(this.state.surfaceImage,
      this.surfaceImageIndexCoords.x, this.surfaceImageIndexCoords.y, // location on source image we are pulling from
      canvasDimensions.surfaceImageSize.x, canvasDimensions.surfaceImageSize.y, // dimensions of source image to pull from
      canvasDimensions.surfaceTopLeftX, canvasDimensions.surfaceTopLeftY, // location on canvas to place
      canvasDimensions.surfaceWidthOnCanvas, canvasDimensions.surfaceHeightOnCanvas); // size of image on canvas

    // draw crosshairs
    if (this.state.crosshairEnabled) {
      let crosshairLength = canvasDimensions.crosshairLength;
      let crosshairDistanceFromCenter = canvasDimensions.crosshairStartFromCenter;
      let centerX = canvasDimensions.surfaceCenterX;
      let centerY = canvasDimensions.surfaceCenterY;

      ctx.strokeStyle = 'black'
      ctx.lineWidth = 3;
      ctx.beginPath();
      // left crosshair
      ctx.moveTo(centerX - crosshairDistanceFromCenter, centerY);
      ctx.lineTo(centerX - crosshairDistanceFromCenter - crosshairLength, centerY);
      //right crosshair
      ctx.moveTo(centerX + crosshairDistanceFromCenter, centerY);
      ctx.lineTo(centerX + crosshairDistanceFromCenter + crosshairLength, centerY);
      //top crosshair
      ctx.moveTo(centerX, centerY - crosshairDistanceFromCenter);
      ctx.lineTo(centerX, centerY - crosshairDistanceFromCenter - crosshairLength);
      // bottom crosshair
      ctx.moveTo(centerX, centerY + crosshairDistanceFromCenter);
      ctx.lineTo(centerX, centerY + crosshairDistanceFromCenter + crosshairLength);
      ctx.stroke();
    }
  }

  // convert lci position into the relevant surface frame
  updateSurfaceAndLciImage = () => {

    let surfaceImagesInRow = this.canvasDimensions.maxCombinedSurfaceImageWidth / this.canvasDimensions.surfaceImageSize.x;
    // get x pixel of where we are in the lci image
    this.rawPosition = this.canvasDimensions.width / 2 - this.lciPosition
    // new surface image every 60 pixels of lci
    let surfaceImageCount = getSurfaceImageCountFromString(this.props.recording.surface);
    this.surfaceImageIndex = Math.floor(this.rawPosition / (this.state.lciImage.naturalWidth / surfaceImageCount));
    // find the appropriate surface image that matches the lci position
    let x = (Math.floor(this.surfaceImageIndex) * this.canvasDimensions.surfaceImageSize.x) % this.canvasDimensions.maxCombinedSurfaceImageWidth;
    let y = Math.floor(this.surfaceImageIndex / surfaceImagesInRow) * this.canvasDimensions.surfaceImageSize.y;

    // ensure we don't go past the end of the combined surface image image
    x = Math.min(x, this.canvasDimensions.maxCombinedSurfaceImageWidth - this.canvasDimensions.surfaceImageSize.x)
    y = Math.min(y, this.state.surfaceImage.naturalHeight - this.canvasDimensions.surfaceImageSize.y)

    this.surfaceImageIndexCoords = {
      x: x,
      y: y
    }
  }

  renderImages = (options = {}, includeColormap = true) => {

    // update image data based on selections	
    // use a key system to store and retrieve previously rendered versions to prevent re-rendering of existing image versions	
    let processedImageData;
    let key = '';

    options = {
      normalized: options.normalized ? options.normalized : this.state.normalizedSelected,
      artifact: options.artifact ? options.artifact : this.state.backgroundRemovalSelected,
      scaled: options.scaled ? options.scaled : false,   //do not scale unless specifically requested
      depth: options.depth ? options.depth : this.state.depthIntensityCompensation,
      ticks: options.ticks ? options.ticks : this.state.showArtifactList,
      colormap: options.colormap ? options.colormap : this.state.selectedColorMap
    };

    key = "key:" + options.normalized + options.artifact + options.scaled + options.depth + options.colormap + options.ticks;

    if (this.renderedImages[key]) processedImageData = this.renderedImages[key];
    else {
      processedImageData = this.lciImageData;
      if (options.normalized) processedImageData = normalizeImage(this.lciImageData, this.lciCanvas.getContext('2d'));
      if (options.artifact) {
        processedImageData = removeFixedArtifacts(processedImageData, this.lciCanvas.getContext('2d'));
      }
      if (options.scaled) processedImageData = scaleImageData(processedImageData, this.canvasDimensions.zoomScaleFactor, this.lciCanvas.getContext('2d'));
      if (options.depth) processedImageData = depthIntensityCompensation(processedImageData, this.lciCanvas.getContext('2d'));
      if (options.colormap) processedImageData = createColorOverlay(processedImageData, options.colormap, this.lciCanvas.getContext('2d'));
      if (options.ticks) processedImageData = addTicksToLci(processedImageData);
      this.renderedImages[key] = processedImageData;
    }
    //this.lciCtx.putImageData(processedImageData, 0, 0);
    return processedImageData;
  }

  componentDidMount() {
    if (this.props.recording.lci === null || this.props.recording.surface === null) {
      let errors = { ...this.state.errors }
      errors.hasImageLoadError = true;
      this.setState({ errors: errors });
    } else {
      this.loadImages();
    }
    window.addEventListener('resize', () => { this.setState({ canvasLoaded: false }) });

    // update url bar with selected practice, device and exam
    if (this.props.recordingNumber === this.props.currentRecording) {
      let url = '/examdata2/' + this.props.examInfo.practice.name + '/' + this.props.examInfo.deviceId.name + '/' + this.props.examInfo.examNumber.name + '/' + this.props.examInfo.examDate + '/' + this.props.examInfo.examTime + '/'+ this.props.recordingNumber;
      window.history.pushState(null, null, url);
    }
    this.colormapList = generateColorMapList();
  }

  componentDidUpdate(previousProps) {

    // if there's an error loading images, stop trying to update canvas 
    if (this.state.errors.hasImageLoadError) return;
    // if passed a new exam, get new images and reset
    if (this.props.examLoaded && previousProps.examInfo !== this.props.examInfo) {
      this.setState({ canvasLoadStarted: false, canvasLoaded: false })
    }

    // if we finished loading images set canvas loading to true
    else if (this.state.lciImage !== null && this.state.surfaceImage !== null && this.state.canvasLoadStarted && !this.state.canvasLoaded && this.props.examLoaded) {
      this.initSurfaceCanvas();
    }
    // if recordingNumber changes and this component becomes the active one, load
    else if (previousProps.currentRecording !== this.props.currentRecording && this.state.canvasLoaded) {
      if (this.props.currentRecording === this.props.recordingNumber) {
        this.initSurfaceCanvas();
        // update url bar with selected practice, device and exam
        let url =  '/examdata2/' + this.props.examInfo.practice.name + '/' + this.props.examInfo.deviceId.name + '/' + this.props.examInfo.examNumber.name + '/' + this.props.recordingNumber;
        window.history.pushState(null, null, url);
      }
    }
    // this function will only rerender if we don't have a version of the imagedata
    if (this.state.canvasLoaded) {
      this.processedImageData = this.renderImages();
      this.lciCtx.putImageData(this.processedImageData, 0, 0);
      this.zoomLciCtx.putImageData(this.renderImages({ scaled: true }), 0, 0);
      this.updateCanvas();

      if (this.state.lciImage && this.state.lciImage.complete && this.state.artifactInfo === undefined) {
        this.setState({ artifactInfo: detectArtifacts(this.lciImageData, this.fixedArtifactExamPixelsStart,this.fixedArtifactExamPixelsDuration) });
      }
    }

    // If there is a new request to export a segment, process it
    if (this.props.exportRequestPending && !previousProps.exportRequestPending) {
      this.exportSegment();
    }
  }

  handleMouseDown = (e) => {
    let mouseX = e.type === 'touchstart' ? e.touches[0].clientX : e.clientX;
    this.canMouseX = mouseX;
    this.originalLciPosition = this.lciPosition;

    this.isDragging = true;

    // if we are in the setting state, store location of current position on click, 
    if (this.state.turbidityState === 'set') {
      this.turbidityPosition = { x: this.canvasDimensions.width / 2 - this.lciPosition, y: this.mouseY - this.canvasDimensions.lciTopLeftY };
      let turbidityPosition = { ...this.turbidityPosition };
      turbidityPosition.y = turbidityPosition.y * 480 / this.canvasDimensions.lciHeightOnCanvas;
      let turbidityRatio = computeTurbidityRatio(turbidityPosition, this.lciImageData, this.turbidityBoxSize);

      //this.lciCtx.putImageData(this.processedImageData, 0, 0);
      this.setState({ turbidityState: 'none', turbidityRatio: turbidityRatio });
    }
  }

  handleMouseUp = (e) => {
    this.isDragging = false;
  }

  handleMouseOut = (e) => {
    // user has left the canvas, so clear the drag flag
    this.Dragging = false;
  }

  handleMouseMove = (e) => {
    // get position of mouse (for desktop) or touch (for mobile) and correct for location of canvas in page
    var rect = this.canvasDimensions.boundingClientRect;
    this.mouseX = (e.type === 'touchmove' ? e.touches[0].clientX : e.clientX) - rect.left;
    this.mouseY = (e.type === 'touchmove' ? e.touches[0].clientY : e.clientY) - rect.top;

    // if the drag flag is set, update the position of the lci and redraw the canvas
    if (this.isDragging && this.state.canvasLoaded && this.state.turbidityState === 'none') {
      this.lciPosition = Math.min(this.originalLciPosition + this.mouseX - this.canMouseX, this.canvasDimensions.width / 2);
      this.lciPosition = Math.max(this.lciPosition, this.canvasDimensions.width / 2 - this.state.lciImage.naturalWidth);
      this.updateCanvas();
    }

    // if we are in the turbidity location setting state, update the canvas based on the newly stored mouse information
    else if (this.state.canvasLoaded && this.state.turbidityState === 'set') {
      this.updateCanvas();
    }
  }

  handleToggleCrosshairs = (e) => {
    this.setState({ crosshairEnabled: !this.state.crosshairEnabled });

  }

  handleTurbidityButton = (e) => {
    if (this.state.turbidityState === 'none') this.setState({ turbidityState: 'set' });
    if (this.turbidityPosition !== null) {
      this.setState({ turbidityState: 'none', turbidityRatio: null });
      this.turbidityPosition = null;
    }
  }

  handleImageTypeSelect = (e) => {

    if (e.target.id === 'normalize_button') {
      this.setState({ normalizedSelected: !this.state.normalizedSelected });

    }
    else if (e.target.id === 'background_button') {
      this.setState({ backgroundRemovalSelected: !this.state.backgroundRemovalSelected });

    }
    else if (e.target.id === 'colormap_selector') {
      this.setState({ selectedColorMap: e.target.value });

    }
    else if (e.target.id === 'depth_comp_button') {
      this.setState({ depthIntensityCompensation: !this.state.depthIntensityCompensation });

    }
  }

  handleShowArtifact = (e) => {
    this.setState({'showArtifactList': !this.state.showArtifactList});
  }

  handleHighlightArtifact = (e) => {
    this.setState({'highlightedArtifact': e.target.dataset.key});
  }

  renderArtifactList = (artifactInfo) => {
    if(artifactInfo === undefined) return (<p>No artifacts found</p>);
    let artifactList = artifactInfo.artifactList;
    if(artifactList.length == 0) return (<p>No artifacts found</p>);
    return artifactList.map((artifact) => {
          const lineString = artifact.line.toString().padStart(3, '\u00A0');
          const widthString = artifact.width.toString().padStart(3, '\u00A0');
          const intensityString = artifact.intensity.toString().padStart(3, '\u00A0');
          return (<li key={artifact.id} data-key={artifact.id}
                  onMouseEnter={this.handleHighlightArtifact}
                  >{`Line: ${lineString} |  Width: ${widthString} | Intensity: ${intensityString}`}</li>)
    })
  }

  /* Function to download the center segment of the lci and the current surface image.
     This feature was developed to export images easily for ML use.
  */ 
  exportSegment = () => {

    let ctx = this.exportCanvasRef.current.getContext('2d');
    ctx.canvas.width = 480; // 480 is width of surface image
    ctx.canvas.height = 960; // LCI is 480 also, so combined image is 960
    let lciLeft = this.rawPosition - 240;
    ctx.drawImage(this.state.surfaceImage,
      this.surfaceImageIndexCoords.x, this.surfaceImageIndexCoords.y, // location on source image we are pulling from
      480, 480, // dimensions of source image to pull from
      0, 0, // location on canvas to place
      480, 480); // size of image on canvas

    // ** draw the lci image
    ctx.drawImage(this.lciCanvas, 
      lciLeft, 0, 
      480, 480, 
      0, 480, 
      480, 480);

    // convert canvas to an image url and simulate a download click
    let export_canvas = document.getElementById("export-canvas");
    let link = document.createElement('a');
    link.download = String(this.props.recording.deviceId).replace(":", "-") + "_" +
                      this.props.recording.examNumber + "_" +
                      String(this.props.recording.recordingNumber).padStart(5,"0") + "_" +
                      String(this.surfaceImageIndex).padStart(5,"0") + ".png";
    link.href = export_canvas.toDataURL("image/png");
    link.click();
    this.props.handleExportSegmentResponse();
  }

  render() {
    if (!this.props.examLoaded) return (<div className={css.loading_div}>Loading recording</div>);

    let isHidden = this.props.currentRecording !== this.props.recording.recordingNumber ? { 'display': 'none' } : {}
    let artifactList = this.renderArtifactList(this.state.artifactInfo);
    let artifactMessage = "";
    if (this.state.artifactInfo && this.state.artifactInfo.examTooShort) {
      artifactMessage = `WARNING: exam does not meet the minimum length for artifact detection (${this.fixedArtifactExamStart + this.fixedArtifactExamDuration}s)` 
    }
    if (this.state.errors.hasImageLoadError) return (<div className={css.error_div} style={isHidden}>Error loading recording</div>);
    else {
      return (
        <div className={css.recording_div} style={isHidden}>
          <div id={css.ui_controls}>
            <input id="crosshair_button" type='button' className={`${css.toggle_button} ${css.ui_button}`} value={this.state.crosshairEnabled ? 'Hide crosshairs' : 'Show crosshairs'} onClick={this.handleToggleCrosshairs} />
            <input id="turbidity_button" type='button' className={`${css.turbidity_button} ${css.ui_button}`} value={this.turbidityPosition === null ? 'Turbidity Score' : '(reset) Turbidity Score'} onClick={this.handleTurbidityButton} />
            <input id="normalize_button" type='button' className={`${css.normalize_button} ${css.ui_button}`} value={this.state.normalizedSelected ? 'Remove normalization' : 'Normalize Image'} onClick={this.handleImageTypeSelect}/>
            <input id="depth_comp_button" type='button' className={`${css.depth_comp_button} ${css.ui_button}`} value={this.state.depthIntensityCompensation ? 'Remove Depth Compensation' : 'Depth Compensation'} onClick={this.handleImageTypeSelect} />
            <input id="background_button" type='button' className={`${css.background_button} ${css.ui_button}`} value={this.state.backgroundRemovalSelected ? 'Reset artifacts' : 'Remove artifacts'} onClick={this.handleImageTypeSelect} />
            <ColorMapSelector colormapList={this.colormapList} selectedColorMap={this.state.selectedColorMap} onChange={this.handleImageTypeSelect}></ColorMapSelector>
            <input id="artifact_list_button" type='button' className={`${css.artifact_list_button} ${css.ui_button}`} value={this.state.showArtifactList ? 'Hide Artifact List' : 'Show Artifact List'} onClick={this.handleShowArtifact}/>
            <ul className={`${css.artifact_list}`} style={this.state.showArtifactList ? {display: "block"} : {display: "none"}}>
              {artifactList}
              <p id={css.artifact_message}>{artifactMessage}</p>
            </ul>
          </div>
          <canvas className={css.recording_canvas}
            ref={this.canvasRef}
            onMouseDown={this.handleMouseDown}
            onTouchStart={this.handleMouseDown}
            onMouseMove={this.handleMouseMove}
            onTouchMove={this.handleMouseMove}
            onMouseUp={this.handleMouseUp}
            onTouchEnd={this.handleMouseUp}
          />
          <canvas className={css.hidden_canvas} ref={this.exportCanvasRef} id="export-canvas"></canvas>
        </div>
      );
    }
  }
}

export { RecordingImageDisplay }

function ColorMapSelector(props) {

  let filteredListOfColormaps = ['gist_ncar', 'none'];

  let colormapList = [];

  if (props) {
    colormapList = props.colormapList.filter((element) => {
      return filteredListOfColormaps.includes(element.toLowerCase())
    });
  }

  // create colormap list
  return (
    <select id="colormap_selector" className={css.colormap_selector} onChange={props.onChange} value={props.selectedColorMap}>
      <option key='none' value='none'>Select a Colormap</option>
      {

        colormapList ? colormapList.map((element) => {
          return (<option key={element} value={element}>{element}</option>)
        })
          : ''
      }
    </select>
  )
}

const getSurfaceImageCountFromString = (inputString) => {
  try {
    let removedFileEndString = inputString.split('.')[0];
    return parseInt(removedFileEndString.split('_')[3])
  } catch {
    return null;
  }
}

// set a contrast change for a given center and multiple
const setContrast = (multiple, center, pixels) => {
  for (let i = 0; i < pixels.length; i++) {
    // skip alpha
    if ((i + 1) % 4 === 0) continue;
    pixels[i] = pixels[i] > center ? Math.min((pixels[i] - center) * multiple + center, 255) : Math.max(center - ((center - pixels[i]) * multiple), 0)
  }
}

const normalizeImage = (imageData, context) => {

  let normalizedImage = context.createImageData(imageData);

  // find the brightest pixel
  let brightestPixel = 0;
  for (let i = 0; i < imageData.data.length; i++) {
    // skip alpha
    if ((i + 1) % 4 === 0) continue;
    if (imageData.data[i] > brightestPixel) brightestPixel = imageData.data[i];
  }

  let normalizationFactor = 255 / brightestPixel;

  for (let i = 0; i < imageData.data.length; i += 4) {
    normalizedImage.data[i] = imageData.data[i] * normalizationFactor;
    normalizedImage.data[i + 1] = imageData.data[i + 1] * normalizationFactor;
    normalizedImage.data[i + 2] = imageData.data[i + 2] * normalizationFactor;
    normalizedImage.data[i + 3] = 255
  }

  return normalizedImage;
}

// Adds functionality to add a color map overlay to the LCI. Users
// the js-colormaps library for a list of colormaps to use. Currently 
// we alter the dependancy to show only the color maps we are interested
// in.
const createColorOverlay = (imageData, overlayName, context) => {

  let colorOverlay = context.createImageData(imageData);

  for (let i = 0; i < imageData.data.length; i += 4) {
    // skip alpha
    let array = evaluate_cmap(imageData.data[i] / 255, overlayName, false)
    colorOverlay.data[i] = array[0];
    colorOverlay.data[i + 1] = array[1];
    colorOverlay.data[i + 2] = array[2];
    colorOverlay.data[i + 3] = 255;
  }

  return colorOverlay;
}

// Queries the dependancy for a list of colormaps in order to 
// show them to the user as options to select from.
const generateColorMapList = () => {
  let colormapList = [];
  for (const colormap in data) {
    colormapList.push(colormap);
  }
  return colormapList;
}

// Creates the marker across the LCI to indicate lci brightness 
// exceeds the threshold for brightness. Intended to match device
// threshold, however, it currently seems to be more generous. 
const createGoodSignalLine = (lciImageData, context) => {

  let signalImageData = context.createImageData(lciImageData.width, 10);

  let lineBrightness = new Array(lciImageData.width).fill(0);

  for (let i = 0; i < lciImageData.data.length; i += 4) {
    let line = i % ((lciImageData.width * 4)) / 4;
    lineBrightness[line] += lciImageData.data[i];
  }

  for (let i = 0; i < signalImageData.data.length; i += 4) {
    let line = i % ((signalImageData.width * 4)) / 4;
    let isOverThreshold = lineBrightness[line] > 1000;
    signalImageData.data[i + 0] = isOverThreshold ? 0 : 255
    signalImageData.data[i + 1] = isOverThreshold ? 200 : 255
    signalImageData.data[i + 2] = isOverThreshold ? 0 : 255
    signalImageData.data[i + 3] = isOverThreshold ? 255 : 0;
  }

  return signalImageData;
}


// Experimental function to remove artifacts in the LCI. The algorthim
// subtracts artifacts from the image by looking at each horizontal lines
// and subtracting the brightness of the Nth percentile of brightness of a 
// continous section of pixels from the pixel.
const removeFixedArtifacts = (lciImageData, context) => {

  let modifiedImageData = context.createImageData(lciImageData);
  let pixelCompareLength = 160;
  let pixelArray = new Array(pixelCompareLength);

  for (let i = 0; i < lciImageData.data.length; i += 16) {
    // create an array of pixelCompareLength of the pixels we want to use
    let startOfLine = (Math.floor(i / (lciImageData.width * 4))) * lciImageData.width * 4
    // find the pixel for our array of comparison pixels, ensure we don't start before this line
    let arrayStartingPixel = Math.max(i - (pixelCompareLength / 2 * 4), startOfLine);
    // ensure we don't go over the end of the line
    arrayStartingPixel = Math.min(startOfLine + lciImageData.width * 4 - pixelCompareLength * 16, arrayStartingPixel);

    // fill the array
    for (let j = 0; j < pixelCompareLength; j++) {
      pixelArray[j] = lciImageData.data[arrayStartingPixel + (j * 16)];
    }

    // sort the array and get the 5th lowest pixel value
    pixelArray.sort((a, b) => a - b);
    let backgroundCancelThreshold = pixelArray[5];

    // subtract the calculation from the pixel 
    for (let j = 0; j < 16; j += 4) {
      modifiedImageData.data[i + j] = Math.max(lciImageData.data[i] - backgroundCancelThreshold, 0);
      modifiedImageData.data[i + j + 1] = Math.max(lciImageData.data[i + 1] - backgroundCancelThreshold, 0);
      modifiedImageData.data[i + j + 2] = Math.max(lciImageData.data[i + 2] - backgroundCancelThreshold, 0);
      modifiedImageData.data[i + j + 3] = 255;
    }
  }
  return modifiedImageData;
}

// Un-scale the LCI pixels
// And also convert to single-channel
// So that each pixel is only one value
const reduceLCIArray = (lciPixelArray) => {
  const numChannels = 4, stretchFactor = 4;
  let reducedArray = new Array(Math.floor(lciPixelArray.length / (numChannels * stretchFactor)));
  for (let i = 0, j = 0; i < lciPixelArray.length; i = i + 16, j = j + 1) {
    reducedArray[j] = lciPixelArray[i];
  }
  return reducedArray;
}

// For each line in an exam count of pixels brighter than brightnessCutoff and the average brightness up to fixedLength pixels.
// If exam width < fixedLength, set a flag on the returned value.
// Return artifactInfo with attributes artifactList (list of objects) and examTooShort (boolean)
const detectArtifacts = (lciImageData, fixedStart, fixedDuration) => {
  const numRows = lciImageData.height;
  const pixelsPerRow = Math.floor(lciImageData.width / 4);
  let artifactInfo = {};
  artifactInfo.artifactList = undefined;
  let columnStart = fixedStart;
  let columnEnd;
  if (pixelsPerRow >= fixedStart + fixedDuration) {
    columnEnd = fixedStart + fixedDuration;
    artifactInfo.examTooShort = false;
  } else {
    columnEnd = pixelsPerRow;
    artifactInfo.examTooShort = true;
  }
  let columnDuration = columnEnd - columnStart;
  let lineAverages = new Array(numRows);
  let lineBrightCount = new Array(numRows);
  // Get simpler LCI Array for convenience writing this function.
  let lciPixels = reduceLCIArray(lciImageData.data);
  let brightnessCutoff = 8;
  // One loop to get average value and number of bright pixels
  for (let row = 0; row < numRows; row++) {
    let rowStart = row * pixelsPerRow;
    let rowSum = 0, brightCount = 0;
    for (let column = columnStart; column < columnEnd; column++) {
      rowSum += lciPixels[rowStart + column];
      if (lciPixels[rowStart + column] > brightnessCutoff) {
        brightCount++;
      }
    }
    lineAverages[row] = rowSum / columnDuration;
    lineBrightCount[row] = brightCount;
  }
  const maxPercentBright = 0.7; // max % of pixels that can be bright before considering a row an artifact.
  const brightCountCutoff = columnDuration * maxPercentBright;
  let artifactList = []
  let currentArtifact = undefined;
  for (let row = 0; row < numRows; row++) {
    // Artifact conditional check
    if (lineBrightCount[row] > brightCountCutoff) {
      if (currentArtifact) {
        currentArtifact.width++;
        currentArtifact.intensity = currentArtifact.intensity + lineAverages[row];
      } else {
        currentArtifact = {'line': row, 'intensity': lineAverages[row], 'width': 1};
      }
    } else if (currentArtifact !== undefined) {
      currentArtifact.intensity = lineAverages.slice(currentArtifact.line, currentArtifact.line + currentArtifact.width)
                                              .reduce((runningSum, value) => runningSum + value, 0) / currentArtifact.width;
      currentArtifact.intensity = Math.round(currentArtifact.intensity);
      currentArtifact.id = artifactList.length;
      artifactList.push(currentArtifact);
      currentArtifact = undefined;
    }
  }
  artifactInfo.artifactList = artifactList;
  return artifactInfo;
}

// Reduce the array of pixels. No reason to have 4 of each pixel.
// Further reduce the array to be monochrome. No reason to have RGBA when only RGB are used and they are all the same.
// Split up each line into chunks. A line is only an artifact when every chunk meets the artifact criteria.

// This function computes a turbidity score in a matching algorthim to the device. The turbidity
// score returns a ratio which can be converted to a more easily displayable score.

// There are a number of '4' constants in this function.
// When checking pixels, we only need to check every fourth bit. Each pixel has 4 pixels
// Red, Green, Blue, Alpha.
// For LCI images, since it is greyscale, R, G, B are identical.
const computeTurbidityRatio = (turbidityPosition, lciImageData, turbidityBoxSize) => {

  // translate selected location to coordinates on imageData
  let selectedStartX = Math.floor(turbidityPosition.x - turbidityBoxSize.width / 2) * 4;
  let selectedStartY = Math.floor(turbidityPosition.y);
  let referenceStartY = 480 - turbidityBoxSize.height;

  let selectedBrightness  = 0;
  let referenceBrightness = 0;
  let TURBIDITY_REFERENCE_WIDTH_RATIO = 3;

  // compute the brightness of the sample where the user placed the box.
  for (let i = selectedStartY; i < selectedStartY + turbidityBoxSize.height; i++) {
    let startOfLine = i * lciImageData.width * 4;
    let lineStartPixel = startOfLine + selectedStartX;
    for (let j = lineStartPixel; j < lineStartPixel + turbidityBoxSize.width * 4; j += 4) {
      selectedBrightness  += lciImageData.data[j];
    }
  }

  // compute the reference taken from the bottom of the lci image.
  for (let i = referenceStartY; i < referenceStartY + turbidityBoxSize.height; i++) {
    let startOfLine = i * lciImageData.width * 4;
    // set max reference position so reference can't go over end of lci image
    let maxReferenceX = startOfLine + lciImageData.width * 4 - turbidityBoxSize.width * TURBIDITY_REFERENCE_WIDTH_RATIO * 4;
    let lineStartPixel = Math.min(startOfLine + selectedStartX, maxReferenceX);
    for (let j = lineStartPixel; j < lineStartPixel + turbidityBoxSize.width * TURBIDITY_REFERENCE_WIDTH_RATIO * 4; j += 4) {
      referenceBrightness += lciImageData.data[j];
    }
  }
  let adjustedReferenceBrightness = referenceBrightness / TURBIDITY_REFERENCE_WIDTH_RATIO;
  let C0 = 1.1, C1=-0.00396, C2=0.0000651, C3=0, x=turbidityPosition.y;
  let rolloff_coefficient  = C3 * Math.pow(x,3) + C2 * Math.pow(x,2) + C1 * x + C0;
  // factor by location of turbidity box to account for oct rolloff
  let selectedBrightnessAdjustedForRolloff = selectedBrightness + (selectedBrightness - adjustedReferenceBrightness) * rolloff_coefficient;
  let turbidityRatio = selectedBrightnessAdjustedForRolloff / adjustedReferenceBrightness;
  // for debug purposes, return inputs if failure to get a result
  if (!turbidityRatio) {
    console.log("turbidityPosition: " + JSON.stringify(turbidityPosition) + "Ratio: " + turbidityRatio);
    console.log("selectedBrightness: " + selectedBrightness + ". referenceBrightnesS: " + referenceBrightness);
  }
  return turbidityRatio;
}

// Translate the turbidity ratio in a number from 0 to 1 based on a
// scaling obtained through analysis of clinical data. The position
// and value in the scaling object shown in this function is a result
// of clinical analysis. 
const turbidityRatioToSpeedometerLocation = (turbidityRatio) => {
  const scaling = [
    {position: 0.0, value: 4.27},
    {position: 0.4, value: 10.4},
    {position: 0.6, value: 13.7},
    {position: 0.8, value: 23.3},
    {position: 1, value:35.4},
  ];

  var scaledTurbidity = 0;
  var scaleStart = 0;
  for (var i = 0; i < scaling.length; i++) {
    var portion = scaling[i];
    if (turbidityRatio < portion.value) {
      var percent = (turbidityRatio - scaleStart) / portion.value;
      scaledTurbidity += ((portion.position - scaledTurbidity) * percent);
      break;
    } else {
      scaledTurbidity = portion.position;
      scaleStart = portion.value;
    }
  }
  return scaledTurbidity;
} 

// Custom function to scale an LCI image. Don't need to use this often as 
// built in scaling functions are going to be more efficient/better.
// Honestly don't recall why we are using this version instead of using a 
// built in function.
const scaleImageData = (imageData, scale, c) => {
  var scaled = c.createImageData(imageData.width * scale, imageData.height * scale);

  for (var row = 0; row < imageData.height; row++) {
    for (var col = 0; col < imageData.width; col++) {
      var sourcePixel = [
        imageData.data[(row * imageData.width + col) * 4 + 0],
        imageData.data[(row * imageData.width + col) * 4 + 1],
        imageData.data[(row * imageData.width + col) * 4 + 2],
        imageData.data[(row * imageData.width + col) * 4 + 3]
      ];
      for (var y = 0; y < scale; y++) {
        var destRow = row * scale + y;
        for (var x = 0; x < scale; x++) {
          var destCol = col * scale + x;
          for (var i = 0; i < 4; i++) {
            scaled.data[(destRow * scaled.width + destCol) * 4 + i] =
              sourcePixel[i];
          }
        }
      }
    }
  }

  return scaled;
}

// Experimental feature to determine of aiming to counteract the loss
// in signal as you get deeper in a sample would improve the viewing
// of the LCI
const depthIntensityCompensation = (imageData, ctx) => {

  let cols = imageData.width;
  let rows = imageData.height;

  for (let i = 0; i < cols; i++) {

    let pixelBrightnessAccumulator = 0;

    for (let j = 0; j < rows; j++) {

      let pixelLocation = getImageDataIndex(j, i, cols);
      let pixelBrightnessAccumulatorTemp = pixelBrightnessAccumulator;
      pixelBrightnessAccumulator += imageData.data[pixelLocation] * 0.0003;
      imageData.data[pixelLocation] = imageData.data[pixelLocation] * (1 + pixelBrightnessAccumulatorTemp);
      imageData.data[pixelLocation + 1] = imageData.data[pixelLocation + 1] * (1 + pixelBrightnessAccumulatorTemp);
      imageData.data[pixelLocation + 2] = imageData.data[pixelLocation + 2] * (1 + pixelBrightnessAccumulatorTemp);
    }
  }
  return imageData
}


  /*
   * Function to add tick marks at equal points in the exam based on the lci frame rate. Assumes a frame rate of 40fps. 
   */
  const addTicksToLci = (imageData) => {
    let cols = imageData.width;
    let i = 0;
    while (i <= (cols-3)) {
      let isBigTick = i % (40 * 30 * 4) === 0;
      for (let j = 0; j < (isBigTick ? 30 : 20); j++) {
        for (let k = 0; k < 3; k++) {
          let pixelLocation = getImageDataIndex(j, i+k, cols);
          imageData.data[pixelLocation] = isBigTick ? 255 : 0 ;
          imageData.data[pixelLocation + 1] = 255;
          imageData.data[pixelLocation + 2] = 0;
        }
      }
      i += (40 * 5 * 4);
    }
    return imageData
  }

// Canvas image pixel data is stored in a 1d array, making access individual 
// pixels challenging to access.   
// There are many times where it is helpful to get the position in the array
// for where a particular row starts, and what position in the row we are
// interested in.
const getImageDataIndex = (row, col, width) => {

  let startOfRow = row * width * 4;
  let positionInRow = col * 4;
  return startOfRow + positionInRow;
}

